var pageIn = new TimelineMax({
        paused: true,
        onReverseComplete: leavePage
    }),
    graph1 = new TimelineMax({
        paused: true
    }),
    graph2 = new TimelineMax({
        paused: true
    }),
    navigation = new TimelineMax({
        paused: true
    }),
    textBlock = new TimelineMax({
        paused: true
    }),
    team = new TimelineMax({
        paused: true
    });

$(window).bind("pageshow", function (event) {
    if (event.originalEvent.persisted) {
        window.location.reload()
    }
});


$(window).on("load", function () {
    $('.footer').fadeIn();
    pageIn.play();
    graph1.play();
    graph2.play();
    team.play();
});

// Project page load animation
pageIn
    .fromTo('.wave', 0.5, {
        opacity: 0
    }, {
        opacity: 1
    })
    .fromTo('.box', 0.4, {
        opacity: 0,
        x: -400
    }, {
        delay: 0.2,
        opacity: 1,
        x: 0
    })
    .fromTo('h1', 0.4, {
        opacity: 0,
        x: -40
    }, {
        opacity: 1,
        x: 0
    })
    .fromTo('.projects-menu-wrapper', 0.4, {
        opacity: 0,
        x: 400
    }, {
        opacity: 1,
        x: 0
    })
    .fromTo('.team-block', 0.4, {
        opacity: 0,
        top: 50
    }, {
        opacity: 1,
        top: 0
    })
    .fromTo('.map', 0.2, {
        opacity: 0,
        top: 50
    }, {
        opacity: 1,
        top: 0
    })
    .fromTo('.address-wrapper', 0.4, {
        opacity: 0,
        x: 400
    }, {
        opacity: 1,
        x: 0
    });


graph1
    .fromTo('.svg-wrapper svg .f', 0.5, {
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem")
    .fromTo('.svg-wrapper svg .j', 0.5, {
        delay: 0.2,
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem")
    .fromTo('.svg-wrapper svg .b', 0.4, {
        delay: 0.2,
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem")
    .fromTo('.svg-wrapper svg .h', 0.5, {
        delay: 0.2,
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem")
    .fromTo('.svg-wrapper svg .k', 0.4, {
        delay: 0.2,
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem")
    .fromTo('.svg-wrapper svg .d', 0.4, {
        delay: 0.2,
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem")
    .fromTo('.svg-wrapper svg .g', 0.4, {
        delay: 0.2,
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem")
    .fromTo('.svg-wrapper svg .e', 0.5, {
        delay: 0.4,
        scale: 0.9,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.1,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "graph1elem");

graph2
    .fromTo('.svg-wrapper svg .aura0', 0.5, {
        scale: 1,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 0.7,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "aura")
    .fromTo('.svg-wrapper svg .bullet', 0.5, {
        scale: 1,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 1.7,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "aura")
    .fromTo('.svg-wrapper svg .links', 1.5, {
        opacity: 1
    }, {
        opacity: 0.4,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "aura")
    .fromTo('.svg-wrapper svg .links2', 0.5, {
        opacity: 1
    }, {
        opacity: 0.4,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "aura")
    .fromTo('.svg-wrapper svg .rotateYou', 1, {
        scale: 1,
        css: {
            transformOrigin: "center center",
        }
    }, {
        scale: 1.2,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "aura")
    .fromTo('.svg-wrapper svg .aura8', 0.2, {
        scale: 1.2,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 0.7,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "aura")
    .fromTo('.svg-wrapper svg .aura', 0.5, {
        scale: 1,
        css: {
            transformOrigin: "center center"
        }
    }, {
        scale: 0.7,
        repeat: -1,
        yoyo: true,
        ease: Linear.easeNone
    }, "aura");

navigation.fromTo('.navigation', 0.3, {
    y: -100 + "%",
    opacity: 0
}, {
    y: 0 + "%",
    opacity: 1
});

textBlock.fromTo($('.text-block'), 1, {
    opacity: 0,
    height: 0
}, {
    opacity: 1,
    height: "auto"
});

jQuery(document).ready(function () {
    $('.toggle-info').click(function () {
        $(this).toggleClass('more less');
        $(this).text(function (i, text) {
            return text === "More Info" ? "Less Info" : "More Info";
        });


        if (!($('.text-block').hasClass('hide'))) {
            $('.text-block').fadeOut();
            // textBlock.reverse().timeScale(2);
            $('html, body').animate({
                scrollTop: 0
            }, 500);



            setTimeout(function () {
                $('.text-block').toggleClass('hide');
            }, 1000);

        } else {
            $('.text-block').toggleClass('hide');
            $('.text-block').fadeIn();
            textBlock.play();
            $('html, body').animate({
                scrollTop: $(".text-block").offset().top
            }, 1000);
        }


    });
    $('.header a:not(".menu-button"), a.nav-arrow, .projects-menu a, .footer a, .logo, .button').click(function (event) {
        event.preventDefault();
        window.destination = $(this).attr('href');
        pageIn.reverse().timeScale(4);
    });

    $('.menu-button').click(function () {
        $('body, html').addClass('noscroll');
        navigation.play();
    });
    $('.close-btn').click(function () {
        navigation.reverse();
        $('body, html').removeClass('noscroll');
    });

    $('#submitBtn').on("click", function (e) {
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "submit.php",
            data: $("#contactForm").serializeArray()
        }).done(function (response) {

            $('.msg').addClass('alert-message');
            $('.msg').text(response);

        });

    })


});

function leavePage() {
    window.location.href = window.destination;
}